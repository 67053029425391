<template>
  <div class="print-page row bg-grey-8 q-pt-xl" v-if="projectEntity">
    <div class="toolbar q-px-lg q-py-sm text-white row justify-between items-center bg-grey-9 fixed-top" style="z-index: 1">
      <div class="title">{{ projectEntity.name }}</div>
      <div class="actions">
        <q-btn flat dense round @click="downloadPdf" >
          <q-icon name="get_app"></q-icon>
        </q-btn>
        <q-btn flat dense round @click="print" class="q-ml-sm">
          <q-icon name="local_printshop"></q-icon>
        </q-btn>
      </div>
    </div>
    <div id="pages-container" class="pages-container q-mx-auto column">
      <div v-for="(page,index) in pages" :key="index" class="page-container column" :class="{ 'safari': $q.platform.is.safari }" @click="selectedPageIndex = index">
        <div class="page column full-height">
          <div class="bordered-container col column">
            <div class="page-header">
              <div class="text-h6">{{ projectEntity.name }}</div>
            </div>
            <div class="page-body col" :style="'background-image: url(' + page.canvas_image + ')'">
              <!-- <div class="full-width full-height">
                <img :src="page.canvas_image" class="full-width full-height" style="object-fit: contain">
              </div> -->
            </div>
          </div>
          <div class="footer row justify-between items-center relative-position">
            <div class="col-auto scale-container" v-if="page.scale && page.scale.value" style="width: 20%">
                <img src="/images/scale.png" class="full-width">
              <div class="text-weight-bold text-grey-7">scala impostata a</div>
              <div class="text-weight-bold">mt {{ page.scale.value.toFixed(2) }}</div>
            </div>
            <div class="col-auto stamp-container full-height" :data-value="totalArea ? totalArea.toFixed(2) : '0.00'">
              <img src="/images/areaplan-MQ.png" class="full-height">
            </div>
          </div>
        </div>
      </div>
      <div class="page-container">
        <div class="page column full-height">
          <div class="bordered-container col column">
            <div class="page-header col-auto">
              <div class="text-h6">{{ projectEntity.name }}</div>
            </div>
            <div class="page-body col q-pa-xl">
              <div class="text-h6 text-center text-weight-bold">{{ $t('La superficie commerciale è') }}</div>
              <div class="text-h2 text-weight-bold text-center q-my-lg">
                {{ totalArea.toFixed(2) }} m²
              </div>
              <img src="/images/divider_coccarda.png" class="full-width">
              <table class="full-width q-mt-md">
                <tbody>
                  <tr class="text-weight-bold">
                    <td></td>
                    <td></td>
                    <td class="vertical-middle text-right">Reali</td>
                    <td></td>
                    <td class="text-no-wrap text-right">Comm.li</td>
                  </tr>
                  <tr class="text-weight-medium" v-for="percentage in filteredPercentages" :key="percentage.value">
                    <td><div :style="`width: 15px; height: 15px; background-color:${percentage.bgColor}`"></div></td>
                    <td class="vertical-middle"><div style="width:300px">{{ $t('app.percentages.' + percentage.value)}}</div></td>
                    <td class="text-no-wrap text-right"><span class="text-weight-bold text-subtitle1">{{ percentage.area.toFixed(2) }} mq</span></td>
                    <td class="text-no-wrap">{{ $t('app.calculated_at', { percentage: percentage.value }) }}</td>
                    <td class="full-width text-no-wrap text-right"><span class="text-weight-bold text-subtitle1">{{ ((percentage.area / 100) * percentage.value).toFixed(2) }} mq</span></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td class="vertical-middle text-left"><q-separator/></td>
                    <td></td>
                    <td class="text-no-wrap text-right"><q-separator/></td>
                  </tr>
                  <tr class="text-weight-bold">
                    <td></td>
                    <td></td>
                    <td style="min-width:85px" class="vertical-middle text-right text-subtitle1 text-weight-bold">{{ realTotalArea.toFixed(2) }} mq</td>
                    <td></td>
                    <td style="min-width:85px" class="vertical-middle text-right text-subtitle1 text-weight-bold">{{ totalArea.toFixed(2) }} mq</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="projectEntity && (projectEntity.square_meter_price || projectEntity.value_notes)">
                <hr style="border: 1px solid #ddd" />
                <div class="text-center q-pt-md">
                  <div class="text-h6 text-weight-bold">{{ $t('Valutazione') }}</div>
                  <div v-if="projectEntity && projectEntity.square_meter_price">
                    {{ $t('Prezzo al mq') }} <strong>€ {{ projectEntity.square_meter_price }}</strong> - {{ $t('Valore immobile') }} <strong>€ {{ totalValue }}</strong>
                  </div>
                </div>
                <div v-if="projectEntity && projectEntity.value_notes" class="q-mt-md">
                  <div style="white-space:pre-wrap">{{ projectEntity.value_notes }}</div>
                </div>
                <hr style="border: 1px solid #ddd" />
              </div>
            </div>
            <div class="col-auto q-pa-xl" v-if="me.company.image">
              <img :src="me.company.image_url" style="max-height: 100px" />
            </div>
          </div>
          <div class="footer flex justify-between relative-position">
            <div></div>
            <div class="stamp-container ml-auto full-height" :data-value="totalArea ? totalArea.toFixed(2) : '0.00'">
              <img src="/images/areaplan-MQ.png" class="full-height">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading, QIcon } from 'quasar'
import User from 'src/store/models/User'
import { cloneDeep, each, filter, find, first } from 'lodash'
import Project from 'src/store/models/Project'
import domToImage from 'dom-to-image'

import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
import area from 'area-polygon'

export default {
  name: 'AreaPlanApp',
  components: {
    QIcon
  },
  data () {
    return {
      pages: [],
      totalArea: 0,
      percentages: [
        {
          value: 100,
          area: 0,
          bgColor: '#FA839A',
          textColor: '#000000'
          // Area rossa
        },
        {
          value: 80,
          area: 0,
          bgColor: '#66101F',
          textColor: '#FFFFFF'
          // Area bordeaux
        },
        {
          value: 75,
          area: 0,
          bgColor: '#8D3B72',
          textColor: '#FFFFFF'
          // Area violetto
        },
        {
          value: 60,
          area: 0,
          bgColor: '#0A2463',
          textColor: '#FFFFFF'
          // Area blu scuro
        },
        {
          value: 50,
          area: 0,
          bgColor: '#7DA5DE',
          textColor: '#000000'
          // Area blu
        },
        {
          value: 40,
          area: 0,
          bgColor: '#285943',
          textColor: '#FFFFFF'
          // Area verde
        },
        {
          value: 33,
          area: 0,
          bgColor: '#F2DE8A',
          textColor: '#000000'
          // Area gialla
        },
        {
          value: 30,
          area: 0,
          bgColor: '#FAA916',
          textColor: '#000000'
          // Area giallo scuro
        },
        {
          value: 25,
          area: 0,
          bgColor: '#8CE6E6',
          textColor: '#000000'
          // Area azzurra
        },
        {
          value: 20,
          area: 0,
          bgColor: '#E0C9DF',
          textColor: '#000000'
          // Area lilla
        },
        {
          value: 15,
          area: 0,
          bgColor: '#C3A29E',
          textColor: '#000000'
          // Area beige
        },
        {
          value: 10,
          area: 0,
          bgColor: '#92DDB9',
          textColor: '#000000'
          // Area verde scuro
        },
        {
          value: 5,
          area: 0,
          bgColor: '#B1F6B1',
          textColor: '#000000'
          // Area verde chiaro
        },
        {
          value: 2,
          area: 0,
          bgColor: '#FCB97D',
          textColor: '#000000'
          // Area arancio
        }
      ]
    }
  },
  computed: {
    me () {
      return User.me()
    },
    projectEntity () {
      return Project.query().with('pages').find(this.$route.params.id)
    },
    filteredPercentages () {
      return filter(this.percentages, (p) => {
        return p.area > 0
      })
    },
    totalValue () {
      if (this.projectEntity.square_meter_price) {
        const value = (this.totalArea * this.projectEntity.square_meter_price).toFixed(0)

        return new Intl.NumberFormat('it-IT').format(value)
      } else {
        return '-'
      }
    },
    realTotalArea () {
      const percentages = this.filteredPercentages
      let totalArea = 0
      for (const percentage of percentages) {
        totalArea += percentage.area
      }
      return totalArea
    }
  },
  methods: {
    print () {
      window.print()
    },
    async downloadPdf () {
      Loading.show()
      const pdf = new jsPDF('p', 'mm', 'a4', true)
      pdf.deletePage(1)
      const pages = document.getElementsByClassName('page')
      for (const page of pages) {
        // await html2canvas(page, {
        //   allowTaint: true,
        //   scrollY: -window.scrollY,
        //   useCORS: true,
        //   scale: 5
        // })
        //   .then(canvas => {
        //     var imgData = canvas.toDataURL('image/jpeg')
        //     pdf.addPage()
        //     pdf.addImage(imgData, 'JPEG', -3, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight())
        //   })
        const scale = 2
        const options = {
          height: page.offsetHeight * scale,
          width: page.offsetWidth * scale,
          style: {
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left',
            width: page.offsetWidth + 'px',
            height: page.offsetHeight + 'px'
          }
        }
        await domToImage.toPng(page, options)
          .then(dataUrl => {
            pdf.addPage()
            pdf.addImage(dataUrl, 'PNG', -3, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), '', 'FAST')
          })
          .catch(e => {
            console.error(e)
          })
      }
      pdf.save(this.projectEntity.name + '.pdf')
      Loading.hide()
    },
    getObjects (obj) {
      if (obj.objects) {
        return obj.objects
      } else if (obj.getObjects) {
        return obj.getObjects()
      } else {
        return []
      }
    },
    calculateLineLength: (line, group) => {
      let scaleX = line.scaleX, scaleY = line.scaleY
      if (group) {
        scaleX *= group.scaleX
        scaleY *= group.scaleY
      }
      return Math.sqrt(((line.x2 - line.x1) * scaleX) ** 2 + ((line.y2 - line.y1) * scaleY) ** 2)
    },
    calculateArea () {
      let totalArea = 0
      each(this.percentages, p => {
        p.area = 0
      })
      each(this.pages, page => {
        let pageArea = 0
        if (page.data && page.data.objects && Array.isArray(page.data.objects)) {
          each(page.data.objects, object => {
            let partialArea = 0
            if (object.type === 'polygon') {
              partialArea = area(object.points) * object.scaleX * object.scaleY
              let scale = 1
              if (page.scale) {
                const scaleLength = this.calculateLineLength(first(this.getObjects(page.scale)), page.scale)
                scale = parseFloat(page.scale.value) / scaleLength
                partialArea *= scale ** 2
                const percentage = find(this.percentages, { value: object.percentage })
                if (percentage) {
                  percentage.area += partialArea
                }
                pageArea += partialArea * (object.percentage / 100)
              }
            }
          })
          page.area = pageArea
          totalArea += pageArea
        }
        this.totalArea = totalArea
      })
    },
    getProject (projectId) {
      Loading.show()
      Project.api().get(`/api/companies/${this.me.company_id}/projects/${projectId}`)
        .then(() => {
          this.pages = cloneDeep(this.projectEntity.pages).map(p => {
            const canvas = JSON.parse(p.canvas)
            const data = {
              id: p.id,
              page_index: p.page_index,
              data: canvas,
              background_image: canvas.backgroundImage ? canvas.backgroundImage.src : null,
              canvas_image: p.canvas_image ? p.canvas_image : p.background_image_res.original,
              scale: find(canvas.objects, { 'object-type': 'scale' })
            }
            return data
          })
          this.$nextTick(() => {
            this.calculateArea()
          })
        })
        .finally(() => {
          Loading.hide()
        })
    }
  },
  beforeMount () {
    if (this.$route.params.id) {
      this.getProject(this.$route.params.id)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  @media print {
    @page { size: auto; margin: 0; }
    body * {
      visibility: hidden;
      -webkit-print-color-adjust: exact;
    }
    #pages-container, #pages-container * {
      visibility: visible;
    }
    #pages-container {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
    #pages-container {
      .page-container {
        height: 29.65cm;
        width: 21cm;
        &.safari {
          height: 29.35cm;
        }
        margin: 0!important;
        box-shadow: none!important;
        // page-break-after: always !important;
        .page {
          margin: 0!important;
          padding: 1.6cm!important;
        }
      }
    }
  }
  .pages-container {
    width: 90vw;
    max-width: 21cm
  }
  .page-container {
    display: flex;
    position: relative;
    width: 100%;
    padding-top: 141%;
    margin: 1vw auto;
    border-radius: 2px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    // page-break-after: always !important;
    .page {
      padding: 2.5vw;
      background: white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .bordered-container {
      width: 100%;
      max-height: 100%;
      flex-grow: 1;
      border: 1px solid #D1D2D3;
      .page-header {
        padding: 10px 20px;
        border-bottom: 1px solid #D1D2D3
      }
      .page-body {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .footer {
      height: 10%;
      padding: 2% 0;
      .scale-container {
        font-size: 12px;
        line-height: 1.3;
        align-self: flex-end;
      }
      .stamp-container {
        position: relative;
        display: flex;
        align-items: center;
        &::before {
          content: attr(data-value) " m²";
          color: white;
          position: absolute;
          font-size: 13px;
          left: 0;
          width: 25%;
          text-align: right;
          transform: translate(0, -25%);
        }
      }
    }
    .separator {
      border-bottom: 1px solid black;
      > div {
        top: -17px;
      }
    }
    .caption {
      font-size: 10px;
      line-height: 1.2;
      padding-top: 4px;
    }
  }
</style>
<style>
  @media print {
    #customerly-container {
      display: none!important;
    }
  }
</style>
