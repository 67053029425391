import { render, staticRenderFns } from "./Print.vue?vue&type=template&id=4d8b8854&scoped=true&"
import script from "./Print.vue?vue&type=script&lang=js&"
export * from "./Print.vue?vue&type=script&lang=js&"
import style0 from "./Print.vue?vue&type=style&index=0&id=4d8b8854&lang=scss&scoped=true&"
import style1 from "./Print.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8b8854",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QSeparator});
